import React, { Fragment, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.scss";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import * as serviceWorker from "./serviceWorker";

// ** Import custom components for redux**
import { Provider } from "react-redux";
import store from "./store/index";
import App from "./components/app";
// login & signup pages
import Login from "./pages/login-page/loginPage";
import Signup from "./pages/signup-page/signupPage";

import TableTest from "./components/tableTest/tableTest";

import ProjectPage from "./components/projects/index.project.component";
import ProjectDetails from "./pages/projectDetails-page/ProjectDetails.page";
import NewCredits from "./pages/credits-page/NewCredits.page";
import ProjectNew from "./pages/projectNew-page/ProjectNew.page";
import DashboardPage from "./pages/dashboard-page/dashboard.page";
import Feedback from "./pages/feedback/feedback.page";

// Context Provider Imports
import { ProvideAuth } from "./data/hooks/useAuth.hook";
// import { UserProfileDataProvider } from "./data/context/userProfileData.context";

// Same as route but only accessible for being logged in
import PrivateRoute from "./components/common/PrivateRoute";
import SignupSuccess from "./pages/signupSucces-page/SignupSucces.page";

import LogRocket from "logrocket";

// Init Important Trackers
LogRocket.init("chh7sf/jar", {
  release: "0.1.0",
});

function Root() {
  useEffect(() => {
    const layout = localStorage.getItem("layout_version");
    document.body.classList.add(layout);

    // Include the Crisp code here, without the <script></script> tags
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "5e679849-a8f8-4671-8d9f-076b0b37b12b";
    (function () {
      let d = document;
      let s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }, []);

  return (
    <div className="App">
      <ProvideAuth>
        <Provider store={store}>
          <BrowserRouter basename={"/"}>
            <ScrollContext>
              <Switch>
                <Fragment>
                  <App>
                    {/* Open routes don't need userProfileData */}
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/login`}
                      component={Login}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/signup`}
                      component={Signup}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/signup/success`}
                      component={SignupSuccess}
                    />

                    <PrivateRoute
                      exact
                      path={`${process.env.PUBLIC_URL}/`}
                      component={DashboardPage}
                    />

                    <PrivateRoute
                      exact
                      path={`${process.env.PUBLIC_URL}/project/`}
                      component={ProjectPage}
                    />

                    <PrivateRoute
                      exact
                      path={`${process.env.PUBLIC_URL}/project/id/:projectID`}
                      component={ProjectDetails}
                    />

                    <PrivateRoute
                      exact
                      path={`${process.env.PUBLIC_URL}/project/new`}
                      component={ProjectNew}
                    />

                    <PrivateRoute
                      exact
                      path={`${process.env.PUBLIC_URL}/tabletest`}
                      component={TableTest}
                    />

                    <PrivateRoute
                      exact
                      path={`${process.env.PUBLIC_URL}/credits/new`}
                      component={NewCredits}
                    />

                    <PrivateRoute
                      exact
                      path={`${process.env.PUBLIC_URL}/feedback`}
                      component={Feedback}
                    />
                  </App>
                </Fragment>
              </Switch>
            </ScrollContext>
          </BrowserRouter>
        </Provider>
      </ProvideAuth>
    </div>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
