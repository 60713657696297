import React from "react";

const DataCard = (props) => {
  const cardName = props.cardName;

  return (
    <div className="card">
      <div className="card-header">
        <h5>{cardName}</h5>
      </div>
      <div className="card-body">
        <div className="user-status table-responsive">
          <table className="table table-bordernone">
            <thead>
              <tr>
                <th scope="col">Keywords</th>
                <th scope="col">20-11-2020</th>
                <th scope="col">21-11-2020</th>
                <th scope="col">22-11-2020</th>
                <th scope="col">23-11-2020</th>
                <th scope="col">24-11-2020</th>
                <th scope="col">25-11-2020</th>
                <th scope="col">26-11-2020</th>
                <th scope="col">27-11-2020</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="font-primary">Keyword 1</td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
              </tr>
              <tr>
                <td className="font-primary">Keyword 2</td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
              </tr>
              <tr>
                <td className="font-primary">Keyword 3</td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
              </tr>
              <tr>
                <td className="font-primary">Keyword 4</td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
              </tr>
              <tr>
                <td className="font-primary">Keyword 5</td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
              </tr>
              <tr>
                <td className="font-primary">Keyword 6</td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
              </tr>
              <tr>
                <td className="font-primary">Keyword 7</td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
                <td className="digits">
                  {Math.floor(Math.random() * 100) + 1}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DataCard;
