import React, { Fragment, useState } from "react";
import logo from "../../assets/images/logo/jar-logo-large.png";
import fireconf, {
  fdb,
  firebaseServerTimeStamp,
} from "../../data/firebase/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
// import { auth } from "firebase";

const Signup = ({ history }) => {
  // const randNr = uuidv4();
  // const randomEmailGenerator = randNr.substring(0, 6) + "@random.nl";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [organization, setOrganization] = useState("");

  // const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const createApiKey = () => {
    const uuid = uuidv4();
    const apiKey = uuid.replace(/-/g, "");

    return apiKey.toUpperCase();
  };

  const signUpAuth = async () => {
    try {
      // Create new User
      const newUser = await fireconf
        .auth()
        .createUserWithEmailAndPassword(email, password);

      // create organization
      const newOrg = await fdb.collection("organizations").add({
        createdAt: firebaseServerTimeStamp,
        organization: organization,
        users: [newUser.user.uid],
      });

      // setting additional user data in DB
      // create function that listens to create of new users in this collection and then update the auth user with displayName, users email en profile IMG.

      await fdb
        .collection("users")
        .doc(newUser.user.uid)
        .set({
          apiKey: createApiKey(),
          createdAt: firebaseServerTimeStamp,
          email: email,
          firstName: firstName,
          lastName: lastName,
          organization: organization,
          organizationID: newOrg.id,
          profileIMG:
            "https://robohash.org/set_set1/" + firstName + "?size=100x100",
        });

      // Tijdelijke oplossing. Hierdoor wordt het klantprofiel niet geladen en voorkomt een foutmelding
      // Doordat de klant moet inloggen wordt dit pas geladen als alles beschikbaar is
      fireconf.auth().signOut();

      history.push(`${process.env.PUBLIC_URL}/login`);

      toast.success(
        `Succesfully created your account ${firstName}. Please check your inbox.`
      );
    } catch (error) {
      setTimeout(() => {
        toast.error(error.message);
      }, 200);
    }
  };

  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="container-fluid">
          {/* <!-- sign up page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-sm-12 p-0">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <img
                        src={logo}
                        alt="JAR Logo"
                        width="450px"
                        height="auto"
                      />
                    </div>
                    <div className="card mt-4 p-4">
                      <h4 className="text-center">NEW USER</h4>
                      <h6 className="text-center">
                        Please enter your details to sign up.
                      </h6>
                      <form className="theme-form">
                        <div className="form-row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="col-form-label">
                                First Name
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="firstName"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="col-form-label">
                                Last Name
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="lastName"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="col-form-label">Organization</label>
                          <input
                            className="form-control"
                            type="text"
                            name="organization"
                            value={organization}
                            onChange={(e) => setOrganization(e.target.value)}
                            placeholder=""
                          />
                        </div>
                        <div className="form-group">
                          <label className="col-form-label">EmailAddress</label>
                          <input
                            className="form-control"
                            type="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder=""
                          />
                        </div>
                        <div className="form-group">
                          <label className="col-form-label">Password</label>
                          <input
                            className="form-control"
                            type="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder=""
                          />
                        </div>
                        <div className="form-row">
                          <div className="col-sm-4">
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={() => signUpAuth()}
                            >
                              Sign Up
                            </button>
                          </div>
                          <div className="col-sm-8">
                            <div className="text-left mt-2 m-l-20">
                              Are you already user?  
                              <a
                                className="btn-link text-capitalize"
                                href="login.html"
                              >
                                Login
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="form-divider"></div>
                        <div className="social mt-3">
                          <div className="form-group btn-showcase d-flex">
                            <button
                              className="btn social-btn btn-fb d-inline-block"
                              disabled
                            >
                              {" "}
                              <i className="fa fa-facebook"></i>
                            </button>
                            <button
                              className="btn social-btn btn-twitter d-inline-block"
                              disabled
                            >
                              <i className="fa fa-google"></i>
                            </button>
                            <button
                              className="btn social-btn btn-google d-inline-block"
                              disabled
                            >
                              <i className="fa fa-twitter"></i>
                            </button>
                            <button
                              className="btn social-btn btn-github d-inline-block"
                              disabled
                            >
                              <i className="fa fa-github"></i>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          {/* <!-- sign up page ends--> */}
        </div>
      </div>
    </Fragment>
  );
};

export default Signup;
