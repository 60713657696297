import {
  Home,
  UserPlus,
  User,
  FolderPlus,
  DollarSign,
  Feather,
} from "react-feather";

export const MENUITEMS = [
  {
    path: "/login",
    title: "login",
    icon: User,
    type: "link",
    active: false,
  },
  {
    path: "/signup",
    title: "signup",
    icon: UserPlus,
    type: "link",
    active: false,
  },
];

export const MENUITEMSUSERLOGGEDIN = [
  {
    path: "/",
    title: "Dashboard",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  {
    path: "/project/new",
    title: "New Project",
    icon: FolderPlus,
    type: "link",
    active: false,
  },

  {
    path: "/credits/new",
    title: "Add credits",
    icon: DollarSign,
    type: "link",
    active: false,
  },
  {
    path: "/feedback",
    title: "Feedback",
    icon: Feather,
    type: "link",
    active: false,
  },
];
