import React, { Fragment } from "react";
// import { useTable, useSortBy, useFilters, usePagination } from "react-table"; // Not needed untill using reactQuery

import useFirestore from "../../data/hooks/useFirestore.hook";
import { useRequireAuth } from "../../data/hooks/useRequireAuth.hook";

// import DataCard from "./../cards/datacard/Data.Card.component"; // Not in use

import { Link } from "react-router-dom";

const Dashboard = () => {
  const auth = useRequireAuth();
  const { docs } = useFirestore("AdaProjects");

  let projectMetaData = [];
  let loginStatus = Boolean;

  if (auth.user) {
    projectMetaData = docs;
    loginStatus = true;
  } else {
    loginStatus = false;
  }

  // console.log("ProjectData: ", projectMetaData);

  const scrapeDateFormatter = (seconds) => {
    const mil = seconds * 1000;
    const dateObj = new Date(mil);
    const dateString = dateObj.toLocaleDateString();
    return dateString;
  };

  // const columns = useMemo(
  //   () => [
  //     {
  //       Header: "Project ID",
  //       accessor: "projectID",
  //       disableFilters: true,
  //     },
  //     {
  //       Header: "Project Name",
  //       accessor: "projectName",
  //     },
  //     {
  //       Header: "Project Location",
  //       accessor: "projectMetaData.location",
  //     },
  //     {
  //       Header: "Total Keywords",
  //       accessor: "keywordsTotal",
  //       disableFilters: true,
  //     },
  //     {
  //       Header: "Scrape Frequency",
  //       accessor: "scrapeDetails.scrapeFrequency",
  //     },

  //     {
  //       Header: "Nr of runs",
  //       accessor: "scrapeDetails.timesScraped",
  //       disableFilters: true,
  //     },
  //   ],
  //   []
  // );

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Project Overview</h5>
                <span>
                  {" "}
                  Showing all <code>
                    {" "}
                    {projectMetaData.length} projects{" "}
                  </code>{" "}
                  for: {auth.user ? auth.user.uid : "No user logged in"}
                </span>
              </div>
              {projectMetaData.length ? (
                <div className="table-responsive table-hover">
                  {/* OLD TABLE */}
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Project ID</th>
                        <th scope="col">Project Name</th>
                        <th scope="col">Scrape Location</th>
                        <th scope="col">Number of keywords</th>
                        <th scope="col">Scrape Frequency</th>
                        <th scope="col">Last Update</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projectMetaData.map((project) => {
                        return (
                          <tr key={project.projectID}>
                            <th scope="row">
                              <Link
                                to={`${process.env.PUBLIC_URL}/project/id/${project.projectID}`}
                              >
                                {project.projectID}
                              </Link>
                            </th>
                            <td>{project.projectName}</td>
                            <td>{project.projectMetaData.location}</td>
                            <td>{project.keywordsTotal}</td>
                            <td>{project.scrapeDetails.scrapeFrequency}</td>
                            <td>
                              {project.scrapeDetails.lastScrape
                                ? scrapeDateFormatter(
                                    project.scrapeDetails.lastScrape.seconds
                                  )
                                : "No scrape done yet"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="ml-4 mt-3">
                  <p>
                    {loginStatus
                      ? "Create your first project, or fetching your projects from the database..."
                      : "Make sure to log in to access your data"}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Dashboard;
