// React components
import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";

// own Oomponents & pages
import Breadcrumb from "../../components/common/breadcrumb";
import UpvotyWidget from "../../assets/external/react-upvoty/index";
import { toast } from "react-toastify";

// firebase
import { firebaseFunctions } from "../../data/firebase/config";

const Feedback = () => {
  const [ssoHash, setSsoHash] = useState(0);

  // const fetchUpVotySSO = async () => {
  //   const res = fireconf.functions().httpsCallable("upVotySSO");
  //   return res.json();
  // };

  // const { data: ssoData, status: ssoStatus } = useQuery(
  //   "upvotySSOHash",
  //   fetchUpVotySSO,
  //   {
  //     staleTime: 1000 * 60 * 60 * 24, // fresh time is 24 hours one hour as this data barely refreshes
  //     cacheTime: 1000 * 60 * 60 * 24, // cache time is 24 hours as this data barely refreshes
  //     keepPreviousData: true,
  //     refetchOnWindowFocus: false,
  //     onError: () => {
  //       toast.error("Something went wrong. Please reload the page.");
  //       console.error("error");
  //     },
  //   }
  // );

  const ssoData = firebaseFunctions.httpsCallable("upVotySSO");

  ssoData().then((result) => {
    setSsoHash(result.data);
  });

  return (
    <Fragment>
      <Breadcrumb title="Feedback" noTitle />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Feedback & Bug Hunting</h5>
                <span>
                  Please let me know anything you find by giving your feedback
                  below. Anything from{" "}
                  <code>
                    bugs{" "}
                    <span role="img" aria-label="bug">
                      🐞
                    </span>
                  </code>{" "}
                  to annoyances and features. You can also vote for ones others
                  have posted.
                </span>
              </div>
              <div className="mt-3">
                {ssoHash ? (
                  <UpvotyWidget
                    baseUrl={"feedback.readingrooster.com/"}
                    ssoToken={ssoHash}
                    boardHash={
                      "9d93e3cad1fd770d74ef84d456b87a0dcab591e50f07f6ec89cf5c5f48e82175"
                    }
                  />
                ) : (
                  <div className="ml-3">
                    <p>Loading...</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Feedback;
