import React, { Fragment, useState, useMemo } from "react";
import DataCard from "../../components/cards/datacard/Data.Card.component";
import DataCardProjectSettings from "../../components/cards/datacard/dataCard.Manual.ProjectSettings.component";
import DataCardOld from "../../components/cards/datacard/Data.Card.Old.component";
import KpiCard from "../../components/cards/kpicard/Kpi.Card.component";

import Breadcrumb from "../../components/common/breadcrumb";
import { useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../data/hooks/useAuth.hook";
import useFirestoreDoc from "../../data/hooks/useFirestoreDoc.hook";

import googleSheetsIcon from "../../assets/images/google-sheets.png";

import { fdb, firebaseFunctions } from "../../data/firebase/config";

const ProjectDetails = () => {
  const { projectID } = useParams();
  const [crawlButtonState, setCrawlButtonState] = useState(false);

  const auth = useAuth();

  // let crawlButtonDisabled = true; // preventing the user from crawling unless the db is checked for actual status

  const dataCardDomainColumns = useMemo(
    () => [
      {
        Header: "Domain tracked",
        accessor: "domainsToCheck",
        disableFilters: true,
      },
    ],
    []
  );

  //
  // const dataCardProjectSettingsColumns = useMemo(
  //   () => [
  //     {
  //       Header: "Country",
  //       accessor: "domainsToCheck",
  //       disableFilters: true,
  //     },
  //     {
  //       Header: "Domain tracked",
  //       accessor: "domainsToCheck",
  //       disableFilters: true,
  //     },
  //     {
  //       Header: "Domain tracked",
  //       accessor: "domainsToCheck",
  //       disableFilters: true,
  //     },
  //     {
  //       Header: "Domain tracked",
  //       accessor: "domainsToCheck",
  //       disableFilters: true,
  //     },
  //   ],
  //   []
  // );

  // this function is purely for copying the data from the webhook.
  function copyWebhook(e) {
    navigator.clipboard.writeText(apiWebhookEndpoint);
    toast.success("Copied webhook data to clipboard!");
  }

  const { data, status, error } = useFirestoreDoc(
    fdb.collection("AdaProjects").doc(projectID)
  );

  if (status === "loading") {
    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 mt-5">Loading project data...</div>
          </div>
        </div>
      </Fragment>
    );
  }

  if (status === "error") {
    return `Error: ${error.message}`;
  }

  if (status === "success") {
    // const crawlButtonDisabled = data.scrapeDetails.scrapeActive;
  }

  // scrapeStatus = huidige aantal keywords processed
  // keywordsTotal * # domains = totale verwachtte scrapes
  const scrapeStatus = Math.round(
    (data.scrapeDetails.scrapeStatus / data.keywordsTotal) * 100
  );

  let crawlJobsDone = data.crawlJobs ? data.crawlJobs.length : 0;

  let dataCardDomainData = [];

  const findAllDomains = (projectData) => {
    projectData.domainsToCheck.forEach((domain) => {
      const row = {
        domainsToCheck: domain,
      };
      dataCardDomainData.push(row);
    });
  };
  findAllDomains(data);

  const scrapeDateFormatter = (seconds) => {
    const mil = seconds * 1000;
    const dateObj = new Date(mil);
    const dateString = dateObj.toLocaleDateString();
    return dateString;
  };

  const apiKey = auth.userProfile.apiKey;
  const clientID = auth.userProfile.organizationID;
  const projectAlias = data.projectAlias;
  const apiWebhookEndpoint = `https://api.readingrooster.com/jar/v1/reporting?projectalias=${projectAlias}&apikey=${apiKey}`;

  const activateCrawl = () => {
    // crawlButtonDisabled = true;
    setCrawlButtonState("true");

    const runCrawlFunction = firebaseFunctions.httpsCallable("activateCrawl");

    const runCrawlData = {
      projectID,
      clientID,
    };

    runCrawlFunction(runCrawlData)
      .then((result) => {
        toast.success(result.data);
      })
      .catch((HttpsError) => {
        toast.error(HttpsError.message);
      });
  };

  // temporarely for debugging

  // let dab = [];
  // const scrapesSnapshot = fdb
  //   .collection("scrapes")
  //   .where("projectID", "==", "1zMJnARLPM4hDYNJO3ot")
  //   .get()
  //   .then((snap) => {
  //     snap.forEach((doc) => {
  //       const scrape = doc.data();
  //       dab.push(scrape.keywordID);
  //     });
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //   });

  // console.log(dab.sort());

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-9 xl-100">
          <Breadcrumb title="Project Overview" parent="projects" noTitle />
        </div>
        <div className="col-xl-3 xl-100">
          <div className="page-header mr-3">
            <button
              className={
                "btn btn-square btn-sm float-right" +
                (data.scrapeDetails.scrapeActive
                  ? " btn-outline-warning"
                  : " btn-outline-success")
              }
              type="button"
              onClick={activateCrawl}
              disabled={data.scrapeDetails.scrapeActive && "disabled"}
            >
              {data.scrapeDetails.scrapeActive
                ? "A crawl is running..."
                : "Run Crawl Now"}
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <KpiCard
            mainKpi={crawlJobsDone ? crawlJobsDone + " Crawls Done" : "0 runs"}
            footerText={
              data.scrapeDetails.lastScrape
                ? "Last scrape done on: " +
                  scrapeDateFormatter(data.scrapeDetails.lastScrape.seconds)
                : "No scrape done yet"
            }
          />
          <KpiCard
            mainKpi={data.keywordsTotal}
            footerText="Number of keywords in this project"
          />
          <KpiCard
            mainKpi={data.scrapeDetails.scrapeFrequency}
            footerText="Scrape frequency"
          />
          <KpiCard
            mainKpi={`${scrapeStatus}%`}
            footerText="Current scrape status"
          />
        </div>
        <div className="row">
          <div className="col-xl-12 xl-100">
            <div className="card">
              <div className="card-body">
                <form>
                  <div className="form-group input-group-square">
                    <label>Reporting WebHook</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="icofont icofont-anchor"></i>
                        </span>
                      </div>
                      <input
                        className="form-control"
                        type="text"
                        value={apiWebhookEndpoint}
                        disabled
                      />
                    </div>
                  </div>
                </form>
                <button
                  className="btn btn-square btn-info btn-sm float-right"
                  type="button"
                  onClick={copyWebhook}
                >
                  Copy WebHook
                </button>{" "}
                <a
                  className="btn btn-square btn-light btn-sm float-right pl-2"
                  role="button"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://docs.google.com/spreadsheets/d/1mmi5gYiGFATDEmp4Cpr46qz1efAvyYUsKwR60uNM1vk/copy"
                >
                  <img
                    src={googleSheetsIcon}
                    alt="Google Sheets Icon"
                    width="16px"
                    height="auto"
                  />{" "}
                  Copy Google Sheet
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 xl-100">
            <DataCard
              columns={dataCardDomainColumns}
              data={dataCardDomainData}
              cardName="Domain insights"
              noPagination
            />
          </div>
          <div className="col-xl-6 xl-100">
            <DataCardProjectSettings
              projectName={data.projectName}
              projectID={projectID}
              projectMetaData={data.projectMetaData}
              projectScrapeDetails={data.scrapeDetails}
              cardName="Project Settings"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 xl-100">
            <DataCardOld cardName="Keywords" />
          </div>
        </div>
        <div className="row pb-4">
          <div className="col-xl-12 xl-100">
            <button
              className="btn btn-square btn-outline-danger btn-sm float-right"
              type="button"
              onClick={copyWebhook}
            >
              Delete this project <br />
              <small>Can't undo this!</small>
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default ProjectDetails;
